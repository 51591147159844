export const GAME_SELECTIONS = [
  {
    gameName: "Codenames",
    recommendedNumberOfPlayers: 4,
    isActive: true
  },
  {
    gameName: "One Night Werewolf",
    recommendedNumberOfPlayers: 6,
    isActive: false
  }
];
